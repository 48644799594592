<template>
  <transition name="fade">
    <div class="catalog-banner__box">
      <div
        class="catalog-banner"
        :key="currentIndex"
        :class="getProductsLength(products)"
      >
        <div class="container">
          <banner-list :products="products" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "CatalogBanner",
  props: {
    products: {
      type: Array,
      default: () => [],
    },
    currentIndex: {
      type: Number,
      required: true,
    },
  },
  components: {
    BannerList: () => import("@/components/banner/BannerList.vue"),
  },
  methods: {
    getProductsLength(products) {
      switch (products.length) {
        case 2:
          return "two";
        case 3:
          return "tree";
        case 4:
          return "four";
        case 5:
          return "five";
        case 6:
          return "six";
        case 7:
          return "seven";
        case 8:
          return "eight";
        case 9:
          return "nine";
        case 10:
          return "ten";
      }
    },
  },
};
</script>

<style>
.page-catalog .catalog-banner {
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  justify-content: center;
}
.catalog-banner .container {
  margin: auto 0;
}
.catalog-banner {
  transition: 1s;
  animation: scale 1s;
}

@keyframes scale {
  0% {
    opacity: 0;
    transform: scale(0.7);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.fade-leave-to {
  opacity: 0;
  transition: 0.8s;
  transform: scale(0.7);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  background-color: #fff;
  width: 100%;
  height: 100%;
}
</style>
